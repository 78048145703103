<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<designer />
		<div class="Kingkongarea">
			<div class="Kingkongarea-chlid" @click="go('Cart')">
				<div><img src="../../assets/cart@2x.png" alt=""></div>
				<div>购物车</div>
			</div>
			<div class="Kingkongarea-chlid" @click="go('Order')">
				<div><img src="../../assets/order@2x.png" alt=""></div>
				<div>我的订单</div>
			</div>
			<div class="Kingkongarea-chlid" @click="go('Collection')">
				<div><img src="../../assets/collection@2x.png" alt=""></div>
				<div>我的关注</div>
			</div>
			<div class="Kingkongarea-chlid" @click="talk">
				<div><img style="border-radius: 50%;" src="../../static/my4.png" alt=""></div>
				<div>我的资讯</div>
			</div>
			<div class="Kingkongarea-chlid" @click="go('Promote')">
				<div><img src="../../assets/promote@2x.png" alt=""></div>
				<div>我的推广</div>
			</div>
			<div class="Kingkongarea-chlid" @click="go('Sales')">
				<div><img src="../../assets/sales@2x.png" alt=""></div>
				<div>销售订单</div>
			</div>
			<div class="Kingkongarea-chlid" @click="go('Data')">
				<div><img src="../../assets/data@2x.png" alt=""></div>
				<div>基本资料</div>
			</div>
			<div class="Kingkongarea-chlid" @click="go('MyVip')">
				<div><img src="../../assets/vipblue.png" alt=""></div>
				<div>VIP会员</div>
			</div>
		</div>
		<info />
		<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
		<foot />
	</div>
</template>
<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {};
		},
		created() {},
		methods: {
			go(url) {
				this.$router.push({
					name: url,
				});
			},
			talk(url) {
				this.$router.push({
					path: `/talk/`,
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.page {
		background: #F6F6F6;

		.Kingkongarea {
			margin-top: 10px;
			margin-bottom: 55px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			font-size: 12px;
			color: #000000;
			background-color: #FFFFFF;
			padding: 17px 11px 0 11px;

			.Kingkongarea-chlid {
				text-align: center;
				margin: 0 5.3% 23px 5.3%;

				img {
					width: 50px;
					height: 50px;
				}
			}

			.Kingkongarea-chlid div:last-child {
				margin-top: 6px;
			}
		}
	}
</style>